import Head from 'next/head'
import LandingPageV2Box from '../components/landing-page/landing-page-v2-box'

export default function Home() {
  // const router = useRouter()
  // useEffect(() => {
  // if (localStorage.getItem('sessionToken')) {
  //   router.push('/search')
  // }
  // })

  return (
    <>
      <Head>
        <title>ALT - Smartest Fashion Search</title>
        <link rel="canonical" href="https://www.myalt.shop" />
        <meta property="og:title" content="ALT - Smartest Fashion Search" />
        <meta
          property="og:description"
          content="Redefining the way you shop for fashion"
        />
        <meta property="og:url" content="https://www.myalt.shop" />
        <meta property="og:image" content="/malt/og-image-2.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ALT Fashion" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ALT - Smartest Fashion Search" />
        <meta
          name="twitter:description"
          content="Redefining the way you shop for fashion"
        />
        <meta
          name="twitter:image"
          content="https://www.myalt.shop/malt/og-image-2.jpg"
        />
        <meta name="robots" content="index, follow" />
      </Head>
      <LandingPageV2Box />
    </>
  )
}
